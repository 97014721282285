.main-thumbnail-right {
  flex: 1;
  position: relative;

  .custom-image {
    position: absolute;
  }
}

.main-thumbnail-right-red-half {
  background-image: url(./assets/red.svg);
  width: clamp(250rem, 100vw / 1100 * 500, 500rem);
  bottom: clamp(14rem, 514rem - 100vw / 1330 * 500, 264rem);
  right: 0;
}

.main-thumbnail-right-boy {
  background-image: url(./assets/boy.svg);
  width: clamp(179rem, 100vw / 1100 * 358, 358rem);
  aspect-ratio: 358/482;
  bottom: clamp(77rem, 435rem - 100vw / 1330 * 358, 256rem);
  right: 88rem;
}

.main-thumbnail-right-house {
  background-image: url(./assets/house.svg);
  width: 156rem;
  bottom: 52rem;
  left: -20rem;
}

.main-thumbnail-right-rocket {
  background-image: url(./assets/rocket.svg);
  width: 130rem;
  top: 50rem;
  right: 10rem;
}

.main-thumbnail-right-galaxy {
  background-image: url(./assets/galaxy.svg);
  width: 207rem;
  aspect-ratio: 207/81;
  top: 120rem;
  left: 0;
}

@media (width <= 900px) {
  .main-thumbnail-right {
    display: none;
  }
}

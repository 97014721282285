.button-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120rem;
  height: 42rem;
  border-radius: 5rem;

  .customized-button {
    padding: 0;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
    line-height: calc(100% - 2rem);
    border: none;
    border-radius: 4rem;

    font-weight: 600;
    font-size: 16rem;
    font-family: var(--overall-font-text);
  }
}

.button-active {
  cursor: pointer;

  .customized-button {
    cursor: pointer;
    transition: background 0.2s linear, color 0.2s linear;

    background: var(--button-background);
    color: var(--button-color);

    &:hover {
      background: var(--button-hover-background);
      color: var(--button-color);
    }

    &:active {
      background: var(--button-press-background);
      color: var(--button-color);
    }
  }
}

.button-disabled {
  cursor: not-allowed;
  background: #e9eef6 !important;

  .customized-button {
    cursor: not-allowed;
    background: #e9eef6 !important;
    color: #727c8a !important;
  }
}

.bottom-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30rem;
}

.bottom-logo {
  margin-bottom: 30rem;
}

.bottom-menu-item {
  font-size: 18rem;
  color: #666666;
  margin: 0;
  margin-bottom: 12rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.bottom-menu-ref {
  height: 40rem;
  width: 40rem;
  background-color: white;
  border-radius: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2rem 10rem 0 rgba($color: #000000, $alpha: 0.1);
}

.bottom-menu-ref-img-x {
  background-image: url(./assets/x.svg);
  width: 15.61rem;
  aspect-ratio: 15.61/12.68;
}

.bottom-menu-ref-img-fb {
  background-image: url(./assets/fb.svg);
  width: 8.96rem;
  aspect-ratio: 8.96/16;
}

.bottom-menu-ref-img-pin {
  background-image: url(./assets/pin.svg);
  width: 19rem;
}

.bottom-menu-ref-img-ins {
  background-image: url(./assets/ins.svg);
  width: 19rem;
}

.bottom-menu-ref-img-tt {
  background-image: url(./assets/tt.svg);
  width: 17rem;
}

.bottom-menu-refs {
  display: flex;
  flex-wrap: wrap;
  gap: 20rem;
}

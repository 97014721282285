.detail-des-body-rate-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 20rem;
  margin-top: 30rem;
  margin-bottom: 16rem;

  .detail-desc-rate {
    margin: 0;
  }
}

.detail-des-body-rate-desc {
  font-size: 20rem;
  text-align: justify;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 11rem;
  cursor: pointer;
  p {
    margin: 0;
  }
}

.menu-item-down {
  background-image: url(./assets/down.svg);
  width: 12rem !important;
  aspect-ratio: 12/7;
}

@keyframes blinkAnimation {
  0% {
    background-color: #d9d9d9;
    color: #d9d9d9;
    opacity: 0.1;
  }
  50% {
    background-color: #f2f2f2;
    color: #f2f2f2;
    opacity: 0.5;
  }
  100% {
    background-color: #d9d9d9;
    color: #d9d9d9;
    opacity: 0.1;
  }
}

.loading-row {
  animation: blinkAnimation 1.5s infinite;
  border-radius: 5rem;
}

.loading-half-row {
  width: 50%;
}

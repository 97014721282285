.green-line-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.green-line-title {
  margin: 0;
  font-weight: 600;
  font-size: 22rem;
  color: #fa7436;
  text-align: center;
  margin: 16rem 0 10rem 0;
}

.green-line-desc {
  margin: 0;
  font-weight: 400;
  font-size: 20rem;
  color: #ffffff;
  text-align: center;
}

.green-line-item-img-air {
  background-image: url(./assets/air.svg);
  width: 40rem;
}

.green-line-item-img-wallet {
  background-image: url(./assets/wallet.svg);
  width: 47rem;
}

.green-line-item-img-map {
  background-image: url(./assets/map.svg);
  width: 42rem;
}

.green-line-item-img-table {
  background-image: url(./assets/table.svg);
  width: 48rem;
}

.sub-thumbnail {
  background-color: #003d72;

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sub-thumbnail-img {
      background-image: url(./assets/books.svg);
      flex: 2;
      aspect-ratio: 2/1;
      margin: 30rem 0;
    }

    .sub-thumbnail-title {
      margin: 42rem 0;
      font-size: 44rem;
      font-weight: 600;
      color: white;
    }

    .sub-thumbnail-desc {
      margin: 0 0 112rem 0;
      font-size: 20rem;
      font-weight: 400;
      color: white;
    }
  }
}

@media (width <=1000px) {
  .sub-thumbnail-img {
    display: none;
  }
}

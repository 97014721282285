.green-line {
  background-color: #404040;

  .green-line-img {
    padding: 70rem 0;
    background-image: url(./assets/green.svg);
    background-size: cover;
    width: 100vw;
    aspect-ratio: auto;

    .green-line-main {
      display: grid;
      gap: 80rem;
    }
  }
}

@media (width>=1280px) {
  .green-line-main {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (1310px > width >= 620px) {
  .green-line-main {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width<620px) {
  .green-line-main {
    grid-template-columns: 1fr;
  }
}

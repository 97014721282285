.hidden-menu-list {
  margin-top: 16rem;
}

.hidden-menu-item {
  cursor: pointer;
  padding: 16rem 0;
  padding-left: 10rem;
  border-bottom: 1rem solid #e9e9e9;
  transition: all 0.2s;

  &:hover {
    background-color: #e9e9e9;
  }
}

@media (width <= 1200px) {
  .full-header {
    display: none;
  }
}

.full-header {
  position: fixed;
  z-index: 1000;
  background-color: #fff;
  width: 100%;
  height: 150px;
  box-shadow: 0px 0.5px 0px rgba(0,0,0,0.1);
}
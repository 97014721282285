@keyframes slideIn {
  from {
    opacity: 1;
    transform: scale(1);
    margin-left: clamp(-432rem, calc(0rem - 50vw - 32rem), -332rem);
  }
  to {
    opacity: 1;
    transform: scale(1);
    margin-left: 0;
  }
}

.hidden-menu {
  width: clamp(200rem, 50vw, 400rem);
  height: calc(100% - 32rem);
  border-radius: 0 10rem 10rem 0 !important;
  animation: slideIn 0.15s ease-in-out forwards !important;

  .button-container {
    width: 100%;
  }

  .language-button {
    margin-top: 16rem;
    .button-container {
      height: 42rem;
    }
  }
}

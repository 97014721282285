.form-buttons {
  display: flex;
  align-items: center;
  gap: 16rem;
  margin-top: 30rem;
  margin-bottom: 100rem;
  width: clamp(0rem, 100vw - 32rem, 600rem);

  .button-container {
    flex: 1;

    .customized-button {
      font-weight: 600;
      font-size: 18rem;
    }

    &:first-child {
      background-color: #fa7436;
      .customized-button {
        background-color: white;
        color: #fa7436;
      }
    }

    &:last-child {
      background-color: #fa7436;
      .customized-button {
        background-color: #fa7436;
        color: white;
      }
    }
  }
}

.footer {
  background-color: #fef6e2;

  .wrap {
    position: relative;

    .footer-ref {
      display: flex;
      justify-content: space-between;
      padding: 416rem 0 52rem 0;
      row-gap: 30rem;
    }
  }
}

@media (width <950px) {
  .footer-ref {
    flex-direction: column;
  }
}

.mini-img {
  margin-bottom: 10rem;
  border: 1rem solid #e5e5e5;
  width: 64rem;
  aspect-ratio: 1/1;
  cursor: pointer;
  transition: all 0.2s linear;
  font-size: 0;

  &:hover {
    margin-left: -5rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: inherit;
    aspect-ratio: inherit;
  }
}

.mini-img-active {
  border-color: #1e5751;
}

@font-face {
  font-family: "Bebeboo";
  src: url("../Assets/Fonts/Bebeboo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Volkhov";
  src: url("../Assets/Fonts/Volkhov-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../Assets/Fonts/Poppins-Regular.ttf") format("truetype");
}

:root {
  --overall-font-text: "Bebeboo", sans-serif;
  --overall-font-header: "Volkhov", sans-serif;
  --overall-font-button: "Poppins", sans-serif;
  --overall-color-text: #222222;
}

body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

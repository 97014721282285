.detail-des-body-icon {
  width: 20rem;
}

.detail-des-body-gift {
  background-image: url("./assets/gift.svg");
}

.detail-des-body-air {
  background-image: url("./assets/air.svg");
}

.detail-des-body-book {
  background-image: url("./assets/book.svg");
}

.detail-des-body-text {
  font-size: 20rem;
  margin-bottom: 20rem;
  text-align: justify;
}

.detail-des-body-list {
  display: flex;
  gap: 6rem;
  font-size: 20rem;
  margin-bottom: 8rem;

  &:last-child {
    margin-bottom: 20rem;
  }
}

.detail-des-body-price {
  font-size: 24rem;
  font-weight: 600;
  color: #fa7436;
  margin: 20rem 0;
}

.detail-des-body-btn {
  background-color: #fa7436;
  color: white;
  width: 100%;
  height: 50rem;

  .customized-button {
    font-size: 24rem;
  }
}

.subcribe {
  position: absolute;
  top: -70rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1170rem;
  background-color: #fa7436;
  padding: 80rem 0;
  border-radius: 16rem;

  .subcribe-title {
    text-align: center;
    font-size: clamp(28rem, 100vw/428 * 28, 48rem);
    font-weight: 600;
    font-family: var(--overall-font-header);
    color: white;
    max-width: 674rem;
    padding: 0 16rem;
  }

  .subcribe-input {
    margin-top: 64rem;
    width: clamp(0rem, 100vw - 64rem, 674rem);
    border-radius: 10rem;
    .customized-input-main {
      width: clamp(0rem, 100vw /422 * 611, 611rem);
      height: 68rem;
      font-size: 18rem;

      &::placeholder {
        font-size: 18rem;
      }
    }
  }

  .subcribe-mail {
    background-image: url(./assets/mail.svg);
    width: 18.67rem;
    aspect-ratio: 18.67 / 16;
  }

  .subcribe-btn {
    height: 50rem;
    background-color: #fa7436;

    .customized-button {
      background-color: #fa7436;
      font-weight: 600;
      font-size: 16rem;
      color: white;
    }
  }
}

.subcribe-btn-outside {
  margin-top: 20rem;
  background-color: white;
  height: 52rem;
  color: #fa7436;
}

@media (width >=500px) {
  .subcribe-btn-outside {
    display: none;
  }
}

@media (500px > width) {
  .subcribe-btn {
    display: none;
  }

  .subcribe-btn-outside {
    display: block;
  }
}

@media (width > 860px) {
  .subcribe {
    left: 55rem;
    right: 55rem;
  }
}

@media (width <=860px) {
  .subcribe {
    left: 16rem;
    right: 16rem;
  }
}

.cart-page-wrap {
  max-width: 1280rem;
  margin: auto;
  padding: 30px 16rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cart-item-wrap {
  flex: 5;
  margin-right: 40rem;
  .cart-item-header {
    display: flex;
    justify-content: space-between;
    &__title {
      font-size: 35rem;
      font-weight: bold;
    }
    &__back {
      display: flex;
      align-items: center;
      span {
        padding: 0 10rem;
      }
    }
  }
}

.cart-item {
  display: flex;
  // justify-content: space-between;
  padding: 25px;
  border: 1px solid #e5e5e5;
  align-items: start;
  margin-bottom: 20px;
  &-thumnail {
    border: 1px solid #e5e5e5;
    padding: 20rem;
    width: 200px;
    object-fit: cover;
  }
  &-detail {
    padding: 0 20px;
    &__title {
      font-size: 25rem;
      font-weight: bold;
      margin: 0;
    }
    &__desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-quantity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          border-radius: 5px;
          border: 1px solid #bfbfbf;
          height: 28px;
          width: 50rem;
          margin: 0 15rem;
        }
      }

      &-actions {
        p {
          color: #fa7436;
          margin:  10px 0px;
          span {
            padding: 10rem;
          }
        }
      }
    }
  }
  &-summary {
    height: 100%;
    // background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    p {
      font-weight: bold;
    }
    button {
      border: none;
    }
  }
}

.bill-wrap {
  max-width: 350rem;
  display: flex;
  flex: 1;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding: 25px;
}

.bill-title {
  width: 100%;
  text-align: center;
  font-size: 35rem;
  font-weight: bold;
  margin:0;
  padding-bottom: 20px;
}

.bill-desc {
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
}

.bill-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
}

.bill-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
  p {
    max-width: 200rem;
  }
}


.checkout-btn {
  width: 80%;
  margin-top: 28rem;
  background-color: #fa7436;

  .customized-button {
    background-color: #fa7436;
    color: white;
  }
}

.main-thumbnail-left {
  flex: 1;
}

.main-thumbnail-left-flowers {
  background-image: url(./assets/flowers.svg);
  width: clamp(0rem, 100vw - 52rem, 413rem) !important;
  aspect-ratio: 413/146 !important;
  margin-left: 20rem;
}

.main-thumbnail-left-first-header {
  font-size: clamp(0rem, 100vw /442 * 44, 44rem);
  font-weight: 600;
  margin-top: 81rem;
}

@media (width <= 900px) {
  .main-thumbnail-left-first-header {
    margin-top: 16rem;
  }
}

.main-thumbnail-left-second-header {
  font-size: clamp(0rem, 100vw / 442 * 64, 64rem);
  font-weight: 600;
  font-family: var(--overall-font-header);
  margin: 18rem 0 24rem 0;
}

.main-thumbnail-left-paragragh {
  color: #666666;
  font-size: clamp(16rem, 100vw / 442 * 20, 20rem);
  line-height: 40rem;
  margin: 0;
}

.main-thumbnail-left-btn {
  background-color: #fa7436 !important;
  width: 180rem !important;
  height: 60rem !important;
  margin: 24rem 0;

  .customized-button {
    background-color: #ffffff !important;
    color: #fa7436 !important;
    font-size: 18rem !important;
    font-family: var(--overall-font-button) !important;
  }

  &:hover {
    .customized-button {
      color: #ffffff;
    }
  }
}

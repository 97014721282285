@mixin best-sellers-img {
  max-width: 195rem;
}

.new-product {
  background-color: #fef6e2;
  padding: 70rem 0;
}

.new-product-array {
  display: grid;
  gap: 20rem;
  margin-top: 40rem;

  .best-sellers-img {
    height: 193rem;
  }

  .best-sellers-img-1st {
    @include best-sellers-img;
  }

  .best-sellers-img-2nd {
    @include best-sellers-img;
  }

  .best-sellers-img-3rd {
    @include best-sellers-img;
  }

  .best-sellers-img-4th {
    @include best-sellers-img;
  }

  .best-seller-title {
    font-size: 18rem;
  }

  .best-sellers-desc {
    font-size: 16rem;
  }
}

.new-product-title {
  font-weight: 600;
  font-size: clamp(16rem, 100vw/320 * 44, 44rem);
  margin: 0;
}

@media (width>=1310px) {
  .new-product-array {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (1310px > width >= 660px) {
  .new-product-array {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (660px > width >= 400px) {
  .new-product-array {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (400px > width) {
  .new-product-array {
    grid-template-columns: repeat(1, 1fr);
  }
}

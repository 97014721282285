.help-item {
  font-size: 18rem;
  color: #666666;
  margin: 0;
  margin-bottom: 12rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.help-title {
  color: #222222;
  font-weight: 600;
  font-size: 20rem;
  margin: 0;
  margin-bottom: 30rem;
}

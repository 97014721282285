.custom-dropdown {
  position: relative;

  .dropdown-button {
    width: 150rem;
    height: 28rem;
    border-radius: 14rem;
    background-color: #d9d9d9;
    transition: background-color 0.2s linear;

    .customized-button {
      border-radius: 13rem;
      background-color: #f9f9f9;
      font-size: 14rem;
      text-align: initial;
      padding: 0 16rem;

      .dropdown-placeholder-selected {
        color: var(--overall-main-black);
      }

      .dropdown-placeholder-unselected {
        color: var(--select-button-border);
      }
    }

    &:hover {
      background-color: #d9d9d9;

      .customized-button {
        .dropdown-placeholder-selected {
          color: var(--select-selected-color);
        }
        .dropdown-placeholder-icon {
          border-top-color: var(--overall-main-blue);
        }
      }
    }

    &:active {
      background-color: #d9d9d9;
      .customized-button {
        .dropdown-placeholder-selected {
          color: var(--button-press-background);
        }
        .dropdown-placeholder-icon {
          border-top-color: var(--button-press-background);
        }
      }
    }
  }
}

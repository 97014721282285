@media (width > 1200px) {
  .mobile-header {
    display: none;
  }
}

.mobile-header {
  .wrap {
    padding: 16rem;
  }
}

.icon-feature {
  display: flex;
  align-items: center;
  gap: 24rem;

  .icon-feature-heart {
    cursor: pointer;
    background-image: url(./assets/heart.svg);
    width: 24rem;
  }

  .icon-feature-bag {
    cursor: pointer;
    background-image: url(./assets/bag.svg);
    width: 24rem;
  }

  .icon-feature-bag-wrap {
    position: relative;
    p {
      font-weight: bold;
      font-size: small;
      z-index: 10;
      border-radius: 100%;
      background-color: black;
      width: 20rem;
      height: 20rem;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -21px;
      left: 13px;
      position: absolute;
    }
  }
}

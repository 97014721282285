.input-form-warp {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.input-form-lable {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: medium;
    span {
        padding: 0 5px;
        color: red;

    }
}

.input-form-item {
    outline: none;
    height: 35px;
    border: 1px solid #e5e5e5;
    border-radius:5px ;
}

.input-form-helperText {
    font-size: smaller;
    color: red
}
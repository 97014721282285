.wrap {
  display: grid;
  gap: 16rem;
}

.stick-detail {
  display: flex;
  flex-direction: column;
  gap: 16rem;
}

@media (width > 1200px) {
  .main-detail {
    .wrap {
      grid-template-columns: 66rem repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
    }
  }

  .mini-img-wrap {
    grid-area: 1 / 1 / 3 / span 1;
  }

  .img-carousel {
    grid-area: 1 / 2 / 3 / span 1;
    width: clamp(
      0rem,
      (100vw - 64rem - 66rem) / 2 - 2rem,
      (1280rem - 64rem - 66rem) / 2 - 2rem
    );
  }

  .detail-desc-header {
    grid-area: 1 / 3 / span 1 / span 1;
  }

  .stick-detail {
    grid-area: 2 / 3 / span 1 / span 1;
  }
}

@media (1200px >= width > 800px) {
  .main-detail {
    .wrap {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
    }
  }

  .mini-img-wrap {
    display: none;
  }

  .img-carousel {
    grid-area: 1 / 1 / 3 / span 1;
    width: clamp(
      0rem,
      (100vw - 48rem) / 2 - 2rem,
      (1280rem - 48rem) / 2 - 2rem
    );
  }

  .detail-desc-header {
    grid-area: 1 / 2 / span 1 / span 1;
  }

  .stick-detail {
    grid-area: 2 / 2 / span 1 / span 1;
  }
}

@media (800px >=width) {
  .main-detail {
    .wrap {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
    }
  }

  .mini-img-wrap {
    display: none;
  }

  .img-carousel {
    grid-row: 2 / span 1;
    width: clamp(0rem, 100vw - 32rem - 2rem, 1280rem - 32rem - 2rem);
  }

  .detail-desc-header {
    grid-row: 1 / span 1;
  }

  .stick-detail {
    grid-row: 3 / span 1;
  }
}

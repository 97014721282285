.language-item {
  display: flex;
  align-items: center;
  gap: 8rem;
  font-size: 14rem;
  font-weight: 400;

  .language-item-flag {
    width: 18rem;
    aspect-ratio: 18/12;
  }

  .language-item-eng {
    background-image: url(./assets/eng.svg);
  }

  .language-item-vie {
    background-image: url(./assets/vie.svg);
  }
}

.custom-radio {
  max-width: 1000rem;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  gap: 16rem;

  .custom-radio-label {
    cursor: pointer;
    display: block;
    text-align: center;

    font-size: 22rem;
    font-weight: 400;

    border: 2rem solid #4e9b5f;
    border-radius: 5rem;

    width: 286rem;
    height: 38rem;
    line-height: 38rem;

    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
    transition: background-color 0.25s linear;
    -webkit-transition: background-color 0.25s linear;
    transition: color 0.25s linear;
    -webkit-transition: color 0.25s linear;

    div {
      transition: border 0.25s linear !important;
      -webkit-transition: border 0.25s linear !important;
      transition: background-color 0.25s linear !important;
      -webkit-transition: background-color 0.25s linear !important;

      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 10rem;
      border-radius: 10rem;

      max-height: 260rem;
      aspect-ratio: 160/260;
      overflow: hidden;

      img {
        height: 100%;
      }
    }
  }

  .custom-radio-input:checked ~ .custom-radio-label {
    color: white;
    border: 2rem solid #4e9b5f;
    background-color: #4e9b5f;
  }

  .circle-check {
    display: block;
    position: relative;
  }

  .custom-radio-input {
    position: absolute;
    visibility: hidden;
  }

  .outer-circle {
    display: block;
    position: absolute;
    border: 3rem solid #e9eef6;
    border-radius: 100%;
    height: 16rem;
    width: 16rem;

    top: 0;
    left: -20rem;
    z-index: 5;

    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;

    &:before {
      display: block;
      position: absolute;
      content: "";
      border-radius: 100%;
      height: 8rem;
      width: 8rem;
      top: 4rem;
      left: 4rem;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
  }

  .custom-radio-input:checked ~ .outer-circle {
    border: 3rem solid #fa7436;
  }

  .custom-radio-input:checked ~ .outer-circle::before {
    background: #fa7436;
  }

  .img-check {
    .custom-radio-label {
      border: none;

      width: auto;
      height: auto;

      div {
        border: 2rem dashed #e9eef6;
      }
    }

    .custom-radio-input:checked ~ .custom-radio-label {
      border: none;
      background-color: transparent;

      div {
        border: 2rem solid #fa7436;
        background-color: #fa7436;
      }
    }
  }
}

.best-sellers {
  padding: 70rem 0;
}

.best-sellers-title {
  margin: 0;
  font-size: clamp(16rem, 100vw/320 * 44, 44rem);
  font-weight: 600;
}

.best-sellers-array {
  display: grid;
  gap: 20rem;
  margin-top: 40rem;
}

@media (width>=1330px) {
  .best-sellers-array {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (1330px > width >= 670px) {
  .best-sellers-array {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width<670px) {
  .best-sellers-array {
    grid-template-columns: 1fr;
  }
}

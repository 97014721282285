.book-wrap {
    max-width: 1000px;
    margin:20px auto;
    cursor: pointer;
}

.flipping-page {}

.dedicate-btn {
    background-color: black;
    color: white;
    width: 150px;
    height: 50px;
    border: 1px solid black;
    border-radius: 10px;
    position: absolute;
    bottom: -25px;
    left: calc(50% - 75px);
}
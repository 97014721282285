.dedicate-modal {
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  &-close {
    // justify-content: end;
    margin-left: auto;
  }
  &-title {
    font-weight: bold;
  }

  &-textarea {
    padding: 5px;
    outline: none;
    border: 1px solid #989595;
    margin-bottom: 20px;
    resize: none;
  }

  &-btn {
    padding: 5px 40px;
    height: 40px;
    border: none;
    margin: 0 auto;
    background-color: black;
    color: #fff;
  }
}

@mixin best-sellers-img {
  width: inherit;
  max-width: 305rem;
}

.best-seller-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.best-sellers-img {
  width: 100%;
  height: 300rem;
  border: 1rem solid #d9d9d9;
  border-radius: 5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.best-seller-title {
  margin: 0;
  font-size: 24rem;
  font-weight: 600;
  margin: 16rem 0 10rem 0;
}

.best-sellers-desc {
  margin: 0;
  font-size: 20rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  max-height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.best-seller-btn {
  margin-top: 28rem;
  background-color: #fa7436;

  .customized-button {
    background-color: #fa7436;
    color: white;
  }

  &:hover {
    .customized-button {
      color: white;
    }
  }
}

.best-sellers-img-src {
  width: inherit;
  height: inherit;
}

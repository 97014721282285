.img-carousel {
  aspect-ratio: 1/1;
  border: 1rem solid #e5e5e5;
  font-size: 0;

  .img-carousel-item {
    aspect-ratio: 1/1;
  }

  .slick-dots {
    bottom: 10rem;

    button {
      padding: 0;

      &::before {
        font-size: 16rem;
        transition: opacity 0.2s linear;
      }
    }
  }

  .slick-arrow {
    z-index: 1;
    border-top: 16rem solid transparent;
    border-bottom: 16rem solid transparent;
    transition: border-color 0.2s linear;

    &::before {
      display: none;
    }
  }

  .slick-prev {
    left: 10rem;
    border-right: 16rem solid rgba($color: #000000, $alpha: 0.25);

    &:hover {
      border-right-color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .slick-next {
    right: 10rem;
    border-left: 16rem solid rgba($color: #000000, $alpha: 0.25);

    &:hover {
      border-left-color: rgba($color: #000000, $alpha: 0.5);
    }
  }
}

.dropdown-placeholder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: color 0.2s linear;

  .dropdown-placeholder-icon {
    border-top: 4rem solid var(--overall-color-text);
    border-right: 4rem solid white;
    border-left: 4rem solid white;
    transition: border-top-color 0.2s linear;
  }
}

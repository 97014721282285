.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16rem 0 24rem 0;
  border-top: 1rem solid #999999;

  .copyright-text {
    font-size: 18rem;
    color: #666666;
  }
}

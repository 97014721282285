.custom-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 44rem;
}

.custom-form-title {
  font-weight: 600;
  font-size: 27rem;
  text-align: center;
}

.custom-form-desc {
  font-size: 16rem;
  font-weight: 400;
  color: #666666;
  margin: 10rem 0;
  text-align: center;
}

.custom-form-input {
  border: 1rem solid #e9eef6;

  .customized-input-main {
    height: 48rem;
    width: clamp(0rem, 100vw - 32rem - 34rem, 600rem - 34rem);
    font-weight: 400;
    font-size: 18rem;
    color: #2a2f36;

    &::placeholder {
      font-weight: 400;
      font-size: 18rem;
    }
  }
}

.preview-character {
  width: 250px;
  border: 1px solid #d8dde4;
  margin: 20px;
}

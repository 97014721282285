.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color:rgba(0,0,0,0.4);;

  animation: fadeInBack 0.15s ease-in-out forwards;
}

.custom-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
}

.custom-modal-center {
  justify-content: center;
  align-items: center;
}

.custom-modal-drawer {
  // justify-content: center;
  align-items: center;
}

.custom-modal {
  padding: 16rem;
  border-radius: 10rem;
  z-index: 100;
  background: white;
  animation: fadeIn 0.15s ease-in-out forwards;
}

@keyframes fadeInBack {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.detail-desc-rate {
  display: flex;
  gap: 10rem;
  margin-top: 16rem;
}

.detail-desc-rate-star {
  background-image: url(./assets/star.svg);
  width: 28rem;
}

.detail-desc-tittle {
  font-weight: 600;
  font-size: 39rem;
}

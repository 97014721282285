.dropdown-item {
  flex: 1;
  padding: 0 16rem;
  cursor: pointer;
  transition: 0.2s linear background;

  .dropdown-item-wrap {
    border-bottom: 1rem solid #f3f7fc;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown-item-icon {
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-image: url("./Assets/selected.svg");
      aspect-ratio: 1/1;
    }
  }

  &:hover {
    background: #e9e9e9;
  }
}

.dropdown-item-active {
  font-weight: 600;
  color: var(--select-selected-color);

  .dropdown-item-icon {
    width: 20rem;
  }
}

.dropdown-item-inactive {
  font-weight: 400;
  color: var(--overall-main-black);
  .dropdown-item-icon {
    width: 0;
  }
}

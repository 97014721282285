.checkout-page-wrap {
  max-width: 1280rem;
  margin: auto;
  padding: 30px 16rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.form-wrap {
  flex: 1;
  margin-right: 50px;
  &-back {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      margin-right: 10px;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border: 1px solid #e5e5e5 ;
}


.order-wrap {
  min-width:400px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  padding: 40px;
}

.order-title {
  width: 100%;
  text-align: center;
  font-size: 35rem;
  font-weight: bold;
  margin:0;
  padding-bottom: 20px;
}

.order-desc {
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
}

.order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  // font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
  p {
    max-width: 200rem;
  }
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
  text-align: start;
  font-weight: bold;
  border-bottom: 1px solid #e5e5e5;
  p {
    max-width: 200rem;
  }
}

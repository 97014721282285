.menu {
  display: flex;
  align-items: center;
  gap: 40rem;
  padding: 22rem 0;
  position: relative;
}

.menu-collection {
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-wrap: wrap;
  padding: 15px;
  position: absolute;
  width: 100%;
  height: 200px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
  top: 50rem;
  &-item {
    cursor: pointer;
    margin: 5px 0;
    line-height: 30px;
    &:hover {
      color: rgb(0, 179, 255);
    }
  }
}
html {
  /* font-size: clamp(0px, 100vw/610, 1px); */
  font-size: calc(3pt / 4);
  scrollbar-width: none; /* ẩn thanh cuộn trên Firefox */
  -ms-overflow-style: none; /* ẩn thanh cuộn trên IE và Edge */

  &::-webkit-scrollbar {
    display: none; /* ẩn thanh cuộn trên Chrome và Safari */
  }
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;

  font-family: var(--overall-font-text) !important;
  font-size: 18rem;
  color: var(--overall-color-text);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

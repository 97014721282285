.buttons {
  display: flex;
  justify-content: center;
  gap: 20rem;

  .back-button {
    background: var(--payment-method-border);
    transition: background 0.2s linear, color 0.2s linear;

    .customized-button {
      background: var(--overall-background) !important;
      color: var(--overall-main-black) !important;
    }

    &:hover {
      background: var(--overall-main-blue);
      .customized-button {
        color: var(--overall-main-blue) !important;
      }
    }

    &:active {
      background: var(--button-press-background);
      .customized-button {
        color: var(--button-press-background) !important;
      }
    }
  }
}

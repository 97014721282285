.bottom-filter-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30rem;
}

.bottom-filter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12rem;
  margin-bottom: 12rem;
  border-bottom: 1rem dashed #a3a3a3;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  .bottom-filter-desc {
    margin: 0;
    font-size: 18rem;
    font-weight: 600;
    color: #222222;
  }

  .bottom-filter-img {
    background-image: url(./assets/down.svg);
    width: 24rem;
    margin-left: 40rem;
  }
}

.bottom-filter-refs {
  display: flex;
  flex-wrap: wrap;
  gap: 10rem;

  .bottom-filter-ref {
    background-color: white;
    border-radius: 6rem;
    width: 50rem;
    height: 32rem;
    box-shadow: 0 2rem 10rem 0 rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bottom-filter-ref-vs {
  background-image: url(./assets/vs.svg);
  width: 45rem;
  aspect-ratio: 45/14.43;
}

.bottom-filter-ref-mc {
  background-image: url(./assets/mc.svg);
  width: 32rem;
}

.bottom-filter-ref-pp {
  background-image: url(./assets/pp.svg);
  width: 16.24rem;
  aspect-ratio: 16.24/19.11;
}

.bottom-filter-ref-kl {
  background-image: url(./assets/kl.svg);
  width: 44.64rem;
  aspect-ratio: 44.64/10.25;
}

.bottom-filter-ref-am {
  background-image: url(./assets/am.svg);
  width: 43.03rem;
  aspect-ratio: 43.03/10.76;
}

.bottom-filter-ref-ap {
  background-image: url(./assets/ap.svg);
  width: 42rem;
  aspect-ratio: 42/17.24;
}

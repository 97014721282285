.search-wrap {
  position: relative;
}

.search-input {
  // border-color: #f9f9f9 !important;

  .customized-input-main {
    background-color: #f9f9f9;
    height: 40rem !important;
    width: clamp(0rem, 100vw - 32rem, 400rem);
    font-size: 16rem !important;

    &::placeholder {
      font-size: 16rem !important;
    }
  }
}

.search-icon {
  width: 24rem !important;
  background-image: url(./assets/search.svg);
}
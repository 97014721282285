.searh-drop {
  min-height: 100px;
  z-index: 1000;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
  position: absolute;
  background-color: #fff;
  width: 100%;
  &-item {
    padding: 5px 16px 5px;
    cursor: pointer;
    margin: 0 0;
    line-height: 30px;
    &:hover {
      color: rgb(0, 179, 255);
    }
  }

  &-empty {
    padding: 20px 16px 20px;
    color: #c2b7b7;
  }
}

.order-page-wrap {
  max-width: 1280rem;
  margin: auto;
  padding: 30px 16rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.order-title {
  font-size: large;
  font-weight: bold;
  text-align: start;
}

.order-wrap {
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: start;
}

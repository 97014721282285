.login-signup {
  display: flex;
  align-items: center;
  gap: 16rem;
}

.login-btn {
  background-color: #e5e5e5;
}

.signup-btn {
  background-color: #fa7436;
  color: #ffffff;
}

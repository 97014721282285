.progress-item {
  display: flex;
  gap: 2rem;
}

.progress-item-dash {
  margin: 20rem 0;
  height: 2rem;
}

.progress-item-first-dash {
  width: clamp(10rem, (100vw - 32rem - 42rem * 4) / 8, 40rem);
}

.progress-item-normal-dash {
  width: clamp(20rem, (100vw - 32rem - 42rem * 4) / 4, 80rem);
}

.progress-item-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10rem;
}

.progress-ball {
  width: 42rem;
  height: 42rem;
  line-height: 42rem;
  border-radius: 50%;
  text-align: center;
}

.progress-title {
  font-size: 16rem;
  font-weight: 600;
}

.progress-active {
  .progress-item-dash {
    background-color: #fa7436;
  }

  .progress-ball {
    background-color: #fa7436;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    .progress-checked {
      background-image: url(./assets/checked.svg);
      width: 24rem;
    }
  }

  .progress-title {
    color: #2a2f36;
  }
}

.progress-current {
  .progress-title {
    color: #fa7436;
  }
}

.progress-inactive {
  .progress-item-dash {
    background-color: #e9eef6;
  }

  .progress-ball {
    background-color: #e9eef6;
    color: #2a2f36;
  }

  .progress-title {
    color: #2a2f36;
  }
}
